

import React from "react"
import { graphql } from "gatsby"

//Components
import IndustryPage from "../components/v2/IndustriesPage/IndustryPage"

const IndustryPageTemplate = ({ data }) => {


  const industry = data?.industry?.edges[0]?.node || {}
  return (
    <>
      <IndustryPage industry={industry} />
    </>
  )
}

export const data = graphql`
  query($slug: String!) {
    industry: allWpIndustry (filter:{slug: {eq:$slug}}){
    edges {
      node {
        title
        industry {
          customOnlineMarketplaceCopy
          automatedFulfillmentCopy
          orderManagementCopy
          security
          liveTracking
          automatedLogistics
          automatedSeo
          easyInvoicing
          lowestPrice
        }
      }
    }
  }
}
`

export default IndustryPageTemplate

