import React, { useState } from 'react'
import Layout from '../Layout/layout2'
import styled from 'styled-components'
import SemiTruck from "../../../images/industries-page-background.jpg"
import { StaticImage } from 'gatsby-plugin-image'
import { Grid, Header, Button, Accordion, Divider, Input } from 'semantic-ui-react'
import useWindowDimensions from '../../../hooks/useWindowDimensions'
import AutomatedLogistics from "../../../images/automated-logistics.png"
import OurTechnology from "../../../images/our-technology.png"
import BalanceAndInvoicing from "../../../images/balance-and-invoicing.png"
import CustomOnlineMarketplaces from "../../../images/custom-online-marketplaces.png"
import AutomatedFulfillment from "../../../images/automated-fulfilment.png"
import MickeyOS from '../../../images/mickey-os.png'
import MaterialsXchange from "../../../images/mickey-materialsxchange.png"
import MickeyEnergy from '../../../images/Mickey-Energy.png'
import seo from '../../../images/seo.webp'
import security from '../../../images/security.png'
import tracking from '../../../images/tracking.png'
import truck from '../../../images/truck.png'
import computer from '../../../images/computer.png'
import document from '../../../images/Document-package.png'
import { CaretDown } from "phosphor-react"
import { useStaticQuery, graphql } from 'gatsby'
import { media } from '../../../../mq'
import RequestImg from '../../../images/requestimg.webp';
import Industries from "../../../videos/industries.webmhd.webm"
import { Link } from 'gatsby'

const IndustriesPageWrapper = styled.div`
  width: 100vw;
  overflow: hidden;
  position: relative;
    #video-wrapper {
        height: 100vh;
        width: inherit;
        overflow: inherit;
        position: relative;
        .gatsby-image-wrapper{
           height: calc(100vh - 65px);
        }
        .overlay-text{
            top: 15vh;
            position: absolute;
            height: 100%;
            align-items: center;
            flex-wrap: nowrap;
            color: white;
            width: 100%;
        }
        .background-video {
            min-height: 100%;
            min-width: 100%;
        }
        .ui.grid{
            margin: 0 !important;
        }
    }
    .section-container{
        padding: 3rem 0 3rem 0;
        display: flex;
        flex-direction: column;
        margin-bottom: 50px;
        ${media.smallMedium`flex-direction: row; margin-bottom: 100px;`}
        ${media.smaller`padding: 3rem 5rem;`}
        ${media.xl`justify-content: center;`}
        &#grow{
            flex-direction: column;
            align-items: center;
            ${media.smallMedium`align-items: flex-start;`}
            ${media.large`flex-direction: row;`}
            ${media.xl`flex-direction: align-items: left;`}
            div{
                margin-bottom: 2rem;
                ${media.smallMedium`margin-bottom: 0;`}
                .industry-header{
                    width: 100%;
                    ${media.xl`width: 60%;`}
                }
            }
        }
        &#custom-online-marketpalces{
            flex-direction: column;
            justify-content: center;
            ${media.large`flex-direction: row;`}
            .section-left{
                margin-bottom: 2rem;
                ${media.smallMedium`margin-bottom: 0;`}
            }
            .section-right{}
        }
        &#order-management{
            flex-direction: column-reverse;
            justify-content: center;
            ${media.large`flex-direction: row;`}
            .section-left{}
            .section-right{
                margin-bottom: 2rem;
                ${media.smallMedium`margin-bottom: 0;`}


            }

        }
        &#automated-fulfillment{
            flex-direction: column;
            justify-content: center;
            ${media.large`flex-direction: row;`}
            .section-left{
                margin-bottom: 2rem;
                ${media.smallMedium`margin-bottom: 0;`}
            }

        }
        &#mickey-is-powering{
            justify-content: center;
            div.row{
                justify-content: space-between;
                flex-direction: column;
                max-width: 100%;
                ${media.xl`max-width: 87.5%;`}
                ${media.smallMedium`flex-direction: row;`}
                .mickey-product{
                    padding: 2rem;
                    img{
                        width: 100%;
                        height: auto;
                    }
                    .orange-heading{
                        font-size: 14px !important;
                    }
                    .product-link{
                        font-size: 24px !important;
                        p{
                            line-height: 1.6em;
                            font-size: 16px;
                            font-family: "Brown Regular";
                            margin-top: 1rem;
                        }
                    }

                }
            }
        }
        &#features{
            justify-content: center;
            .row{
                justify-content: space-between;
                flex-direction: column;
                max-width: 100%;
                ${media.xl`max-width: 87.5%;`}
                ${media.smallMedium`flex-direction: row;`}
                .feature{
                    padding: 2rem;
                    img{
                        width: auto;
                        height: 70px !important;
                    }
                    h2{
                        font-size: 24px !important;
                    }
                    p{
                        line-height: 1.6em;
                        font-size: 16px;
                        font-family: "Brown Regular"
                    }
                }
            }
        }
        &#faqs{
            flex-direction: column;
            justify-content: center;
            ${media.smallMedium`flex-direction: row;`}
            .faqs-inner-wrapper{
                margin-bottom: 2rem;
                ${media.smallMedium`margin-bottom: 0;`}
                .faq-list{
                    .title{
                        font-size: 24px;
                        font-family: "Brown Bold";
                        display: flex;
                        justify-content: space-between;
                        transition: .2s ease-in-out;
                        text-align: left !important;
                        line-height: 1.2em;
                        padding: 1rem 0 !important;
                        ${media.small`padding: .5rem 0 !important;`}
                        &.active{
                            color: #f1592a;
                        }
                        .caret{
                            min-width: 24px;
                            min-height: 24px;
                            margin-left: .5rem;
                            transition: .2s ease-in-out;
                            &.active{
                                transform: rotate(180deg);
                            }
                        }
                    }
                    .content{
                        width: 100%;
                        text-align: left !important;
                        ${media.medium`width: 60%;`}
                        p{
                            font-family: "Brown Regular" !important;
                            line-height: 1.6em;
                            font-size: 16px;
                        }
                    }
                }
            }
        }
        &#request-demo{
            justify-content: space-around;
            flex-direction: column;
            align-items: center;
            ${media.large`flex-direction: row;`}
            ${media.xl`justify-content: center;`}
            .request-demo-header{
                width: 100%;
                ${media.large`width: 80%;`}
                .request-demo-subheader{
                    font-size: 16px;
                    line-height: 1.8em;
                    margin-bottom: 2rem;
                    margin-top: 2rem;
                }
            }
            .request-image-wrapper{
                text-align: center;
                margin-top: 4rem;
                ${media.large`text-align: right; margin-top: 0;`}
                    img{
                    width: 100%;
                    height: auto;
                }
            }
            .orange-button-wrapper{
                margin-bottom: 0;
                ${media.small`justify-content: flex-start;`}
            }
            .request-wrapper{
                display: flex;
                flex-direction: column;
                align-items: center;
                ${media.small`flex-direction: row;`}
                .ui.input{
                    border-radius: 50px;
                    border: 1px solid #6d6e70;
                    width: 100%;
                    margin-right: 0;
                    margin-bottom: 2rem;
                    ${media.small`width: 300px; margin-right: 2rem; margin-bottom: 0;`}
                    input{
                        border-radius: 50px;
                        border: none;
                        width: inherit;
                    }
                }
            }
        }
    }
    a{
        color: black;
        transition: .2s ease-in-out;
        &:hover{
        color: #f1592a ;
        }
    }
    h1,h2{
    font-size: 36px !important;
    ${media.smaller`font-size: 48px !important;`}
}
    .industry-header{
        font-size: 30px !important;
        ${media.smaller`font-size: 36px !important;`}
        .industry-subheader{
            font-size: 16px !important;
            line-height: 1.5em !important;
            color: rgba(0,0,0,.6) !important;
            font-family: "Brown Regular";
            margin-top: 1rem !important;
        }
    }
  .orange-heading{
    font-size: 18px;
    color: #f1592a !important;
  }
  .orange-button-wrapper{
    display: flex;
    justify-content: center;
    width: 100%;
    margin-bottom: 3rem;
  }
  .ui.button.orange-button{
      height: 40px;
      font-size: .75rem;
      background-color: white;
      border: 1px solid #f1592a;
      color: #f1592a;
      border-radius: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: .2s ease-in-out;
      &:hover{
        background-color: #f1592a;
        color: white;
      }
    }

`

const IndustryPage = ({ industry }) => {
    const wd = useWindowDimensions()
    const [activeFAQ, setActiveFAQ] = useState(null)
    const [email, setEmail] = useState("")

    const data = useStaticQuery(graphql`
    query FAQsQuery {
      allWpFaq {
        edges {
          node {
            faqs {
              answer
              question
            }
          }
        }
      }
    }
  `)
    const FAQs = data?.allWpFaq.edges

    const handleFAQ = (index) => {
        if (activeFAQ === index) {
            setActiveFAQ(null)
        }
        if (activeFAQ !== index) {
            setActiveFAQ(index)
        }

    }

    const handleEmail = (inputEmail) => {
        setEmail(inputEmail)
    }

    const selectedIndustry = industry?.industry


    return (
        <Layout>
            <IndustriesPageWrapper>
                {
                    wd && wd?.width && <>
                        <div id="video-wrapper">

                            {
                                wd.width <= 540 &&
                                <div className='gatsby-image-wrapper'>
                                    <img src={SemiTruck} />
                                </div>

                            }
                            {
                                wd.width > 540 &&
                                <video
                                    className='background-video'
                                    preload
                                    loop
                                    muted
                                    autoPlay
                                >
                                    <source src={Industries} type="video/webm" />
                                </video>
                            }
                            <Grid
                                className='overlay-text'
                                style={{
                                    flexDirection: "column",
                                    top: wd.width <= 950 && "10vh"
                                }}
                                width={16}
                            >
                                <Grid.Column width={(wd.width <= 950 && wd.width >= 768 && 10) || (wd.width < 768 && 13) || wd.width > 950 && 6} style={{ marginBottom: "1.5rem" }}>
                                    <Header textAlign='center' className='digital' as={"h1"} style={{ textShadow: '2px 1px 2px rgba(0,0,0,0.8)', fontSize: wd.width <= 540 ? "40px" : "65px", color: "inherit" }}>
                                        Expand your B2B wholesale {industry?.title.toLowerCase()} business
                                    </Header>
                                </Grid.Column>
                                <Grid.Column width={(wd.width <= 950 && wd.width >= 768 && 10) || (wd.width < 768 && 13) || wd.width > 950 && 8} style={{ marginBottom: "1.5rem" }}>
                                    <Header textAlign='center' className='mission' as={"p"} style={{ textShadow: '2px 1px 2px rgba(0,0,0,0.8)', fontSize: "20px", color: "inherit" }}>
                                        Instant quotes, automated logistics, no commission. Mickey is the future of how commodity products are bought and sold.
                                    </Header>
                                </Grid.Column>
                                <Grid.Column textAlign='center' width={8} style={{ display: "flex", justifyContent: "center", marginBottom: "5rem" }}>
                                    <Button href="/about" className='orange-button'>
                                        Learn More
                                    </Button>
                                </Grid.Column>
                            </Grid>
                        </div>
                        <Grid stackable width={16} id="grow" className='section-container'>
                            <Grid.Column textAlign={wd.width <= 991 ? "center" : "left"} width={wd.width >= 1600 ? 14 : 10}>
                                <Header className='industry-header'>
                                    Grow your {industry?.title.toLowerCase()} business with intelligent order management and automated fulfillment.
                                </Header>
                            </Grid.Column>
                        </Grid>
                        <Grid stackable width={16} id="custom-online-marketpalces" className='section-container'>
                            <Grid.Column className='section-left' textAlign={wd.width <= 991 ? "center" : "left"} width={wd.width >= 1600 ? 7 : 8 && wd.width <= 1200 ? 16 : 8}>
                                <Header as={"p"} className="orange-heading">Mickey for {industry?.title}</Header>
                                <Header as={"h1"} className="industry-header">
                                    Custom Online Marketplace
                                    <Header.Subheader as={"p"} className="industry-subheader">
                                        {selectedIndustry?.customOnlineMarketplaceCopy}
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                            <Grid.Column className="section-right" width={wd.width >= 1600 ? 7 : 8 && wd.width <= 1200 ? 16 : 8}>
                                <img src={CustomOnlineMarketplaces} className="section-image" />
                            </Grid.Column>
                        </Grid>
                        <Grid className='section-container' id="order-management" stackable width={16} >
                            <Grid.Column width={wd.width >= 1600 ? 7 : 8 && wd.width <= 1200 ? 16 : 8} className="section-left">
                                <img src={BalanceAndInvoicing} className="section-image" />
                            </Grid.Column>
                            <Grid.Column textAlign={wd.width <= 991 ? "center" : "left"} width={wd.width >= 1600 ? 7 : 8 && wd.width <= 1200 ? 16 : 8} className="section-right">
                                <Header as={"p"} className="orange-heading">Mickey for {industry?.title}</Header>
                                <Header as={"h1"} className="industry-header">
                                    Order Management
                                    <Header.Subheader as={"p"} className="industry-subheader">
                                        {selectedIndustry?.orderManagementCopy}
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                        </Grid>
                        <Grid stackable width={16} className='section-container' id="automated-fulfillment">
                            <Grid.Column textAlign={wd.width <= 950 ? "center" : "left"} width={wd.width >= 1600 ? 7 : 8 && wd.width <= 1200 ? 16 : 8} className="section-left">
                                <Header as={"p"} className="orange-heading">Mickey for {industry?.title}</Header>
                                <Header as={"h1"} className="industry-header">
                                    Automated Fulfillment
                                    <Header.Subheader as={"p"} className="industry-subheader">
                                        {selectedIndustry?.automatedFulfillmentCopy}
                                    </Header.Subheader>
                                </Header>
                            </Grid.Column>
                            <Grid.Column width={wd.width >= 1600 ? 7 : 8 && wd.width <= 1200 ? 16 : 8} className="section-right">
                                <img src={OurTechnology} className="section-image" />
                            </Grid.Column>
                        </Grid>
                        <Grid className="section-container" id="mickey-is-powering" width={wd.width >= 1600 ? 14 : 16}>
                            <Grid.Column width={wd.width >= 1600 ? 14 : 16} textAlign="center">
                                <Header as={"h2"}>
                                    Mickey is Powering
                                </Header>
                            </Grid.Column>
                            <Grid.Row>
                                <Grid.Column className="mickey-product" width={wd.width <= 991 ? 16 : 5}>
                                    <img src={MickeyOS} alt='' />
                                    <Header as={"p"} className="orange-heading">Mickey OS</Header>
                                    <Header as={"h2"} className="product-link">
                                        <Link to="/about">
                                            Mickey OS
                                        </Link>
                                        <Header.Subheader as={"p"}>
                                            Automated B2B Marketplace for wholesale products
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column className="mickey-product" width={wd.width <= 991 ? 16 : 5}>
                                    <img src={MaterialsXchange} alt='' />
                                    <Header as={"p"} className="orange-heading">Mickey OS</Header>
                                    <Header as={"h2"} className="product-link" >
                                        <a href="https://marketplace.materialsxchange.com/">
                                            MaterialsXchange
                                        </a>
                                        <Header.Subheader as={"p"}>
                                            The Digital marketplace for Lumber and Panels
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>
                                <Grid.Column className="mickey-product" width={wd.width <= 991 ? 16 : 5}>
                                    <img src={MickeyEnergy} alt='' />
                                    <Header as={"p"} className="orange-heading">Private Software</Header>
                                    <Header as={"h2"} className="product-link">
                                        <Link to="/mickey-energy">
                                            Mickey Energy
                                        </Link>
                                        <Header.Subheader as={"p"}>
                                            Trading Technology for Liquified Natural Gas
                                        </Header.Subheader>
                                    </Header>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        <Grid className="section-container" id="features" stackable width={wd.width >= 1600 ? 14 : 16}>
                            <Grid.Column width={wd.width >= 1600 ? 14 : 16} textAlign="center">
                                <Header as={"h2"}>Our Features</Header>
                                <div className='orange-button-wrapper'>
                                    <Button href="/products" className="orange-button">Learn More</Button>
                                </div>
                            </Grid.Column>
                            <Grid.Row>
                                <Grid.Column className='feature' width={wd.width <= 991 ? 16 : 5}>
                                    <img src={security} alt='' />
                                    <Header as={"h2"}>Security</Header>
                                    <p>{selectedIndustry?.security}</p>
                                </Grid.Column>
                                <Grid.Column className='feature' width={wd.width <= 991 ? 16 : 5}>
                                    <img src={tracking} alt='' />
                                    <Header as={"h2"} >Live Tracking</Header>
                                    <p>{selectedIndustry?.liveTracking}</p>
                                </Grid.Column>
                                <Grid.Column className='feature' width={wd.width <= 991 ? 16 : 5} >
                                    <img src={seo} alt='' />
                                    <Header as={"h2"}>Lowest Price</Header>
                                    <p>{selectedIndustry?.lowestPrice}</p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row>
                                <Grid.Column className='feature' width={wd.width <= 991 ? 16 : 5} >
                                    <img src={truck} alt='' />
                                    <Header as={"h2"} >Automated Logistics</Header>
                                    <p>{selectedIndustry?.automatedLogistics}</p>
                                </Grid.Column>
                                <Grid.Column className='feature' width={wd.width <= 991 ? 16 : 5} >
                                    <img src={document} alt='' />
                                    <Header as={"h2"}>Easy Invoicing</Header>
                                    <p>{selectedIndustry?.easyInvoicing}</p>
                                </Grid.Column>
                                <Grid.Column className='feature' width={wd.width <= 991 ? 16 : 5} >
                                    <img src={computer} alt='' />
                                    <Header as={"h2"}>Automated SEO</Header>
                                    <p>{selectedIndustry?.automatedSeo}</p>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Grid id="faqs" stackable className='section-container'>
                            <Grid.Column className="faqs-inner-wrapper" textAlign={wd.width <= 991 ? "center" : "left"} width={wd.width >= 1600 ? 14 : 16}>
                                <Header as={"p"} className="orange-heading">Mickey OS</Header>
                                <Header as={"h2"}>
                                    Frequently Asked <br />
                                    Questions
                                </Header>
                                <Accordion className='faq-list'>
                                    <Divider />
                                    {
                                        FAQs?.map((faq, index) => {
                                            const question = faq.node.faqs
                                            return (
                                                <>
                                                    <Accordion.Title
                                                        active={activeFAQ === index}
                                                        index={index}
                                                        onClick={() => handleFAQ(index)}
                                                    >
                                                        {question?.question}
                                                        <CaretDown className={activeFAQ === index ? "active caret" : "caret"} />
                                                    </Accordion.Title>
                                                    <Accordion.Content active={activeFAQ === index}>
                                                        <p>
                                                            {question?.answer}
                                                        </p>
                                                    </Accordion.Content>
                                                    <Divider />
                                                </>
                                            )
                                        })
                                    }
                                </Accordion>
                            </Grid.Column>
                        </Grid>
                        <Grid
                            className="section-container"
                            id="request-demo"
                            stackable
                            width={16}
                        >
                            <Grid.Column
                                width={wd.width >= 1600 ? 6 : 8 && wd.width <= 1200 ? 16 : 8}
                            >
                                <Header
                                    className="request-demo-header"
                                    as={"h2"}
                                    textAlign={wd.width <= 768 ? "center" : "left"}
                                >
                                    Request a demo with Mickey today.
                                    <Header.Subheader
                                        className="request-demo-subheader"
                                        as={"p"}
                                    >
                                        Request a demo with us to better understand how Mickey can be implemented at your company.
                                    </Header.Subheader>
                                </Header>
                                <div className="request-wrapper">
                                    <Input
                                        placeholder={"Enter Email..."}
                                        onChange={(e, d) => handleEmail(d?.value)}
                                    />
                                    <div className="orange-button-wrapper">
                                        <Button
                                            className="orange-button"
                                            href={`/request-demo/?email=${email}&origin=Industry%20Page:%20${industry?.title.includes(" ") ? industry?.title.replace(" ", "%20") : industry?.title}`}
                                        >
                                            Request Demo
                                        </Button>
                                    </div>
                                </div>
                            </Grid.Column>
                            <Grid.Column
                                className="request-image-wrapper"
                                width={wd.width >= 1600 ? 8 : 7 && wd.width <= 1200 ? 16 : 7}
                            >
                                <img
                                    src={RequestImg}
                                />
                            </Grid.Column>
                        </Grid>
                    </>
                }
            </IndustriesPageWrapper>
        </Layout>
    )
}

export default IndustryPage